import { Model } from 'pinia-orm';
import { ProductCategory, ProductField, ProductFieldValue, ProductPrice } from '~/models';

export class Product extends Model {
  static override entity = 'orm/projects/products';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      pointTypeId: this.attr(null),
      categoryId: this.attr(null),
      name: this.string(''),
      description: this.string(''),
      unit: this.string(''),
      precision: this.number(0),
      order: this.number(0),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deletedAt: this.attr(null),
      category: this.belongsTo(ProductCategory, 'categoryId'),
      fields: this.belongsToMany(ProductField, ProductFieldValue, 'productId', 'fieldId'),
      fieldValues: this.hasMany(ProductFieldValue, 'productId').onDelete('cascade'),
      prices: this.hasMany(ProductPrice, 'productId'),
    };
  }

  get title() {
    return [
      this.name,
      this.description,
      ...this.fields
        .filter((field: ProductField) => field.pivot.value)
        .map((field: ProductField) => [field.name, field.pivot.value].join(': ')),
    ].filter(Boolean).join('\n');
  }

  declare id: number;
  declare projectId: number;
  declare pointTypeId: number;
  declare categoryId: number | null;
  declare name: string;
  declare description: string | null;
  declare unit: string;
  declare precision: number;
  declare order: number;
  declare createdAt: number | null;
  declare updatedAt: number | null;
  declare deletedAt: number | null;
  declare category: ProductCategory | null;
  declare fields: ProductField[];
  declare fieldValues: ProductFieldValue[];
  declare prices: ProductPrice[];
}
