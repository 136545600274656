import { Model } from 'pinia-orm';

export enum ProjectUserSessionStatus {
  NEW = 'new',
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
}

export class ProjectUserSession extends Model {
  static override entity = 'orm/projectUserSessions';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      userId: this.attr(null),
      userAgent: this.attr(null),
      ip: this.attr(null),
      status: this.attr(null),
      lastActivity: this.attr(null),
    };
  }

  declare id: number;
  declare projectId: number;
  declare userId: number;
  declare userAgent: string;
  declare ip: string;
  declare status: ProjectUserSessionStatus;
}
