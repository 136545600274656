import { Model } from 'pinia-orm';
import { PointTagCategory, PointTagPivot } from '~/models';

export type PointTagSettings = {
  icon: string | null
  iconOnly: boolean
  textColor: string
  bgColor: string
}

export const makePointTagSettings = (): PointTagSettings => ({
  icon: null,
  iconOnly: false,
  textColor: 'black',
  bgColor: 'white',
});

export class PointTag extends Model {
  static override entity = 'orm/projects/points/tags';

  _deleting: boolean = false;

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      categoryId: this.attr(null),
      name: this.string(''),
      description: this.string(null),
      order: this.attr(null),
      settings: this.attr(makePointTagSettings),
      category: this.belongsTo(PointTagCategory, 'categoryId'),
      pointLinks: this.hasMany(PointTagPivot, 'tagId').onDelete('cascade'),
    };
  }

  get title() {
    return [this.name, this.description].filter(Boolean).join('\n');
  }

  declare id: number;
  declare projectId: number;
  declare categoryId: number | null;
  declare name: string;
  declare description: string;
  declare order: number;
  declare settings: PointTagSettings;
  declare category: PointTagCategory | null;
  declare pointLinks: PointTagPivot[];
  declare pivot?: PointTagPivot;
}
