import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isCancel } from 'axios';

export default class BaseAPI {
  private static _axios: AxiosInstance | null = null;

  public static init(axiosInstance: AxiosInstance) {
    this._axios = axiosInstance;
  }

  protected static get $axios(): AxiosInstance {
    if (!this._axios) {
      throw new Error('Axios is not initialized.');
    }

    return this._axios;
  }

  protected static $get<T = any, R = AxiosResponse<T>, D = any>(url: string, opts?: AxiosRequestConfig<D>) {
    return this.$axios.get<T, R, D>(url, opts);
  }

  protected static $post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, opts?: AxiosRequestConfig<D>) {
    return this.$axios.post<T, R, D>(url, data, opts);
  }

  protected static $put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, opts?: AxiosRequestConfig<D>) {
    return this.$axios.put<T, R, D>(url, data, opts);
  }

  protected static $delete<T = any, R = AxiosResponse<T>, D = any>(url: string, opts?: AxiosRequestConfig<D>) {
    return this.$axios.delete<T, R, D>(url, opts);
  }

  public static isCancel(e: any): boolean {
    return isCancel(e);
  }
}
