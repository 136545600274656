import BaseAPI from '~/api/_BaseAPI';
import type { InitResponse } from '~/types';

export default class SiteAPI extends BaseAPI {
  static async init() {
    const { data } = await this.$get<InitResponse>('/');
    return data;
  }

  static async setLocale(locale: string) {
    await this.$post('/set-locale', { locale });
  }
}
