import { Model } from 'pinia-orm';
import { NumberCast } from 'pinia-orm/casts';
import {
  PointCategory, PointDeliveryPrice,
  PointField,
  PointFieldValue,
  type PointIcon,
  PointTag,
  PointTagPivot,
  PointType,
  Product,
  ProductPrice,
  Project,
} from '~/models';

export type Lat = number;
export type Lon = number;

export type LatLon = [
  lat: Lat,
  lon: Lon,
];
export type LonLat = [
  lon: Lon,
  lat: Lat,
];

export interface HasAddress {
  address: string
}

export interface HasCoordinates {
  lat: Lat
  lon: Lon
  hasCoordinates(): boolean
  getLatLon(): LatLon
  getLonLat(): LonLat
}

export interface HasIcon {
  icon?: PointIcon
}

export type GeoPoint = HasAddress & HasCoordinates & HasIcon;

export class Point extends Model implements GeoPoint {
  static override entity = 'orm/projects/points';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      typeId: this.attr(null),
      categoryId: this.attr(null),
      name: this.string(''),
      address: this.string(''),
      lat: this.attr(0),
      lon: this.attr(0),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deletedAt: this.attr(null),
      // relations
      project: this.belongsTo(Project, 'projectId'),
      type: this.belongsTo(PointType, 'typeId'),
      category: this.belongsTo(PointCategory, 'categoryId'),
      products: this.belongsToMany(Product, ProductPrice, 'pointId', 'productId'),
      productPrices: this.hasMany(ProductPrice, 'pointId').onDelete('cascade'),
      fields: this.belongsToMany(PointField, PointFieldValue, 'pointId', 'fieldId'),
      fieldValues: this.hasMany(PointFieldValue, 'pointId').onDelete('cascade'),
      tags: this.belongsToMany(PointTag, PointTagPivot, 'pointId', 'tagId'),
      tagPivots: this.hasMany(PointTagPivot, 'pointId').onDelete('cascade'),
      deliveryPrices: this.hasMany(PointDeliveryPrice, 'pointId').onDelete('cascade'),
    };
  }

  _deleting: boolean = false;

  static override casts() {
    return {
      lat: NumberCast,
      lon: NumberCast,
    };
  }

  get icon(): PointIcon {
    if (this.category?.icon) {
      return this.category.icon;
    }
    if (this.type) {
      return this.type.icon;
    }
    return { type: 'circle', color: 'red' };
  }

  hasCoordinates(): boolean {
    return Boolean(this.lat && this.lon);
  }

  getLatLon(): LatLon {
    return [
      this.lat,
      this.lon,
    ];
  }

  getLonLat(): LonLat {
    return [
      this.lon,
      this.lat,
    ];
  }

  declare id: number;
  declare projectId: number;
  declare typeId: number;
  declare categoryId: number | null;
  declare name: string;
  declare address: string;
  declare lat: Lat;
  declare lon: Lon;
  declare createdAt: number | null;
  declare updatedAt: number | null;
  declare deletedAt: number | null;

  declare project: Project;
  declare type: PointType;
  declare category: PointCategory | null;
  declare products: Product[];
  declare productPrices: ProductPrice[];
  declare fieldValues: PointFieldValue[];
  declare tags: PointTag[];
  declare tagPivots: PointTagPivot[];
  declare deliveryPrices: PointDeliveryPrice[];
}
