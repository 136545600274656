import { Model } from 'pinia-orm';

export class RolePermission extends Model {
  static override entity = 'orm/auth/rolePermission';
  static override primaryKey = ['roleId', 'permissionId'];
  static override fields() {
    return {
      roleId: this.number(null),
      permissionId: this.number(null),
      settings: this.attr({}),
    };
  }

  declare roleId: number;
  declare permissionId: number;
  declare settings: object | any;
}
