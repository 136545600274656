import { Model } from 'pinia-orm';

export class PointFieldEnum extends Model {
  static override entity = 'orm/projects/points/fields/enums';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      fieldId: this.attr(null),
      name: this.string(''),
      code: this.string(null),
      order: this.number(null),
    };
  }

  declare id: number;
  declare projectId: number;
  declare fieldId: number;
  declare name: string;
  declare code: string;
  declare order: number;
}
