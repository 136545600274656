import { Model } from 'pinia-orm';

export class PointTagPivot extends Model {
  static override entity = 'orm/pivot/pointTag';
  static override primaryKey = ['tagId', 'pointId'];

  static override fields() {
    return {
      tagId: this.attr(null),
      pointId: this.attr(null),
      order: this.number(0),
    };
  }

  declare tagId: number;
  declare pointId: number;
  declare order: number;
}
