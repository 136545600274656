import { Model } from 'pinia-orm';

export class ProductFieldValue extends Model {
  static override entity = 'orm/pivot/productFieldValue';
  static override primaryKey = ['fieldId', 'productId'];

  static override fields() {
    return {
      fieldId: this.attr(null),
      productId: this.attr(null),
      value: this.attr(null),
    };
  }

  declare fieldId: number;
  declare productId: number;
  declare value: any;
}
