import { Model } from 'pinia-orm';
import { ProductFieldValue } from '~/models';

export class ProductField extends Model {
  static override entity = 'orm/projects/products/fields';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      pointTypeId: this.attr(null),
      name: this.string(null),
      description: this.string(null),
      order: this.number(null),
      values: this.hasMany(ProductFieldValue, 'fieldId'),
    };
  }

  get title() {
    return [this.name, this.description].filter(Boolean).join('\n');
  }

  declare id: number;
  declare projectId: number;
  declare pointTypeId: number | null;
  declare name: string;
  declare description: string | null;
  declare order: number | null;
  declare values: ProductFieldValue[];
}
