import { Model } from 'pinia-orm';
import { Permission, RolePermission } from '~/models';

export class Role extends Model {
  static override entity = 'orm/auth/roles';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      name: this.string(''),
      permissions: this.belongsToMany(Permission, RolePermission, 'roleId', 'permissionId'),
    };
  }

  declare id: number;
  declare projectId: number | null;
  declare name: string;
  declare permissions: Permission[];
}
