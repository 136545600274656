import { Model } from 'pinia-orm';
import { Point, PointCategory, PointField, type PointIcon, Product, ProductCategory, ProductField } from '~/models';

export class PointType extends Model {
  static override entity = 'orm/projects/points/types';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      name: this.string(''),
      description: this.string(null),
      icon: this.attr({ type: 'circle', color: 'green' }),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      points: this.hasMany(Point, 'typeId').onDelete('cascade'),
      categories: this.hasMany(PointCategory, 'pointTypeId').onDelete('cascade'),
      productFields: this.hasMany(ProductField, 'pointTypeId').onDelete('cascade'),
      productCategories: this.hasMany(ProductCategory, 'pointTypeId').onDelete('cascade'),
      products: this.hasMany(Product, 'pointTypeId').onDelete('cascade'),
      pointFields: this.hasMany(PointField, 'pointTypeId').onDelete('cascade'),
    };
  }

  _deleting: boolean = false;

  get title() {
    return [this.name, this.description].filter(Boolean).join('\n');
  }

  declare id: number;
  declare projectId: number;
  declare name: string;
  declare description: string;
  declare icon: PointIcon;
  declare createdAt: number | null;
  declare updatedAt: number | null;
  declare categories: PointCategory[];
  declare productFields: ProductField[];
  declare productCategories: ProductCategory[];
  declare products: Product[];
  declare pointFields: PointField[];
  declare points: Point[];
}
