interface ILogger {
  debug: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
}

let loggerInstance: ILogger | null = null;

export const _setupLogger = (logger: ILogger) => {
  loggerInstance = logger;
};

export const logger = (): ILogger => {
  if (!loggerInstance) {
    throw new Error('Logger is not initialized');
  }
  return loggerInstance;
};
