import { Model } from 'pinia-orm';
import { RolePermission } from '~/models';

export enum Ability {
  ADMIN_VIEW = 'admin.view',
  SETTINGS_VIEW = 'settings.view',
  SETTINGS_MANAGE = 'settings.manage',
  USERS_VIEW = 'users.view',
  USERS_MANAGE = 'users.manage',
  TYPES_VIEW = 'types.view',
  TYPES_CREATE = 'types.create',
  TYPES_EDIT = 'types.edit',
  TYPES_DELETE = 'types.delete',
  TAGS_CREATE = 'tags.create',
  TAGS_EDIT = 'tags.edit',
  PRODUCTS_CREATE = 'products.create',
  PRODUCTS_EDIT = 'products.edit',
  PRODUCTS_DELETE = 'products.delete',
  TAGS_DELETE = 'tags.delete',
  FIELDS_VIEW = 'fields.view',
  FIELDS_CREATE = 'fields.create',
  FIELDS_EDIT = 'fields.edit',
  FIELDS_DELETE = 'fields.delete',
}

export type ProjectAbility = Exclude<Ability, Ability.ADMIN_VIEW>

export class Permission extends Model {
  static override entity = 'orm/auth/permissions';

  static override fields() {
    return {
      id: this.attr(null),
      name: this.string(''),
    };
  }

  declare id: number;
  declare name: Ability;
  declare pivot: RolePermission | undefined | null;
}
