import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_X7whXyrIWR from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_vue3_r7ltImJ0os from "/app/node_modules/@pinia-orm/nuxt/dist/runtime/plugin.vue3.js";
import _00_logging_rWc1RG0ky0 from "/app/plugins/00.logging.ts";
import _01_init_transport_DObBVjJK0s from "/app/plugins/01.init-transport.ts";
import _02_init_data_QruTZPwHk9 from "/app/plugins/02.init-data.ts";
import _03_dayjs_xZquBqOx7l from "/app/plugins/03.dayjs.ts";
import ckeditor_client_AuHlmi9QPC from "/app/plugins/ckeditor.client.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
import vue_virtual_scroller_client_o9JJatl5SA from "/app/plugins/vue-virtual-scroller.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_vue3_r7ltImJ0os,
  _00_logging_rWc1RG0ky0,
  _01_init_transport_DObBVjJK0s,
  _02_init_data_QruTZPwHk9,
  _03_dayjs_xZquBqOx7l,
  ckeditor_client_AuHlmi9QPC,
  vue_final_modal_pML93k5qcp,
  vue_virtual_scroller_client_o9JJatl5SA
]