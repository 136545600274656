import type { NuxtApp } from '#app/nuxt';
import { serverInit, clientInit } from '#imports';

export default defineNuxtPlugin(async (nuxtApp) => {
  if (process.server) {
    await serverInit(nuxtApp as NuxtApp);
  } else {
    await clientInit(nuxtApp as NuxtApp);
  }
});
