import type { NuxtApp } from '#app/nuxt';
import axios from 'axios';
import { appendResponseHeader, getRequestHeaders as resolveRequestHeaders, H3Event } from 'h3';
import BaseAPI from '~/api/_BaseAPI';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  const $axios = axios.create({
    baseURL: (process.server ? runtimeConfig.internalAPI : runtimeConfig.public.apiBaseUrl) + '/api/web',
    headers: {
      'Accept': 'application/json',
    },
    withCredentials: true,
    timeout: (process.env.NODE_ENV === 'production' ? 30 : 600) * 1000,
  });

  if (process.server) {
    // do not use here composable `useRequestHeaders()`
    const getRequestEvent = () => useRequestEvent(nuxtApp as NuxtApp) as H3Event;
    const getRequestHeaders = (include: string[]): Record<string, string> => {
      const event = getRequestEvent();
      const headers = event ? resolveRequestHeaders(event) : {};
      if (!include) {
        return headers as Record<string, string>;
      }
      return Object.fromEntries(
        include
          .map(key => key.toLowerCase())
          .filter(key => headers[key])
          .map(key => [key, headers[key]]),
      ) as Record<string, string>;
    };

    $axios.interceptors.request.use((ctx) => {
      // we need proxy these headers from browser to API
      const requestHeaders = getRequestHeaders([
        'cookie',
        'user-agent',
        'accept-language',
        'referer',
        'x-real-ip',
      ]);
      for (const headerName in requestHeaders) {
        const headerValue = requestHeaders[headerName];
        if (!headerValue) {
          continue;
        }
        switch (headerName) {
          case 'x-real-ip':
            ctx.headers.set('x-forwarded-for', requestHeaders[headerName]);
            break;
          default:
            ctx.headers.set(headerName, headerValue);
        }
      }
      return ctx;
    });

    $axios.interceptors.response.use((ctx) => {
      const cookies = ctx.headers['set-cookie'] || [];
      const event = getRequestEvent();
      for (const cookie of cookies) {
        appendResponseHeader(event, 'set-cookie', cookie);
      }
      return ctx;
    });
  }

  if (process.client) {
    // todo
    // const laravelEcho = new Echo({
    //   broadcaster: 'pusher',
    //   Pusher,
    //   key: runtimeConfig.public.pusherAppKey,
    //   wsHost: runtimeConfig.public.pusherHost,
    //   wsPort: 80,
    //   wssPort: 443,
    //   cluster: 'eu', // any value
    //   forceTLS: true,
    //   encrypted: true,
    //   disableStats: true,
    //   enabledTransports: ['wss'],
    //   withoutInterceptors: true,
    // });
    //
    // $axios.interceptors.request.use((config) => {
    //   if (laravelEcho.socketId()) {
    //     logger().debug({ 'X-Socket-Id': laravelEcho.socketId() });
    //     config.headers['X-Socket-Id'] = laravelEcho.socketId();
    //   }
    //
    //   return config;
    // });
    //
    // setLaravelEcho(laravelEcho);
  }

  BaseAPI.init($axios);
});
