import BaseAPI from '~/api/_BaseAPI';
import type {
  LoginRequest,
  LoginResponse,
  PasswordRecoveryRequest,
  PasswordRecoveryResponse,
  PasswordResetRequest,
  PasswordResetResponse,
  RegisterRequest,
  RegisterResponse,
  SendVerificationLinkResponse,
  VerifyEmailRequestCode,
  VerifyEmailRequestSigned,
  VerifyEmailResponse,
} from '~/types';

export default class AuthAPI extends BaseAPI {
  static async logout() {
    await this.$post('/auth/logout');
  }

  static async login(requestData: LoginRequest) {
    const { data } = await this.$post<LoginResponse>('/auth/login', requestData);

    return data;
  }

  static async register(requestData: RegisterRequest) {
    const { data } = await this.$post<RegisterResponse>('/auth/register', requestData);

    return data;
  }

  static async passwordRecovery(requestData: PasswordRecoveryRequest) {
    const { data } = await this.$post<PasswordRecoveryResponse>('/auth/password/recovery', requestData);

    return data;
  }

  static async passwordReset(requestData: PasswordResetRequest) {
    const { data } = await this.$post<PasswordResetResponse>('/auth/password/reset', requestData);

    return data;
  }

  static async verifyEmailSigned(requestData: VerifyEmailRequestSigned) {
    const { data } = await this.$get<VerifyEmailResponse>('/auth/verify/email/signed', {
      params: requestData,
    });

    return data;
  }

  static async verifyEmailCode(requestData: VerifyEmailRequestCode) {
    const { data } = await this.$post<VerifyEmailResponse>('/auth/verify/email/code', requestData);

    return data;
  }

  static async sendVerificationCodeToEmail() {
    const { data } = await this.$post<SendVerificationLinkResponse>('/auth/verify/email/send');

    return data;
  }

  static async invited(projectSlug: string, token: string) {
    const { data } = await this.$get(`/auth/invited/${projectSlug}`, { params: { token } });
    return data;
  }
}
