import { consola, type ConsolaOptions, type LogLevel, type LogObject } from 'consola';
import { _setupLogger } from '~/composables/logging';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const logger = consola.create({
    level: parseLogLevel(runtimeConfig.public.logLevel),
  }).withTag('A');
  logger.addReporter({
    log(logObj: LogObject, ctx: { options: ConsolaOptions }): void {
      // todo - add log to sentry or other service
    },
  });

  nuxtApp.hook('app:error', (error) => {
    logger.error(error);
  });
  nuxtApp.hook('vue:error', (error, instance, info) => {
    logger.error(error, info);
  });

  logger.debug('[logger initialized]');

  _setupLogger(logger);

  return {
    provide: {
      logger,
    },
  };
});

const parseLogLevel = (level: string | undefined): LogLevel => {
  switch (level) {
    case 'silent':
      return -999;
    case 'fatal':
    case 'error':
      return 0;
    case 'warn':
      return 1;
    case 'log':
      return 2;
    case 'info':
    case 'success':
    case 'fail':
    case 'ready':
    case 'start':
      return 3;
    case 'debug':
      return 4;
    case 'trace':
      return 5;
    case 'verbose':
      return +999;
    default:
      return 3; // info
  }
};
