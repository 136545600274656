import { Model } from 'pinia-orm';
import { Project, ProjectUser, ProjectUserSession, Role, RoleUser } from '~/models';

export class User extends Model {
  static override entity = 'orm/users';

  static override fields() {
    return {
      id: this.attr(null),
      name: this.string(''),
      email: this.string(''),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      emailVerifiedAt: this.attr(null),
      projectsCount: this.attr(null),
      roles: this.belongsToMany(Role, RoleUser, 'userId', 'roleId'),
      projects: this.belongsToMany(Project, ProjectUser, 'userId', 'projectId'),
      sessions: this.hasMany(ProjectUserSession, 'userId', 'id'),
    };
  }

  get onlyInCurrentProject() {
    return this.projectsCount === 1;
  }

  declare id: number;
  declare name: string;
  declare email: string;
  declare createdAt: number | null;
  declare updatedAt: number | null;
  declare emailVerifiedAt: number | null;
  declare projectsCount: number | null;
  declare roles: Role[];
  declare projects: Project[];
  declare pivot: ProjectUser | undefined;
  declare sessions: ProjectUserSession[];
}
