
export const ormOptions = {
  "autoImports": [
    "useRepo"
  ],
  "model": {
    "namespace": "",
    "withMeta": false,
    "hidden": [
      "_meta"
    ],
    "visible": [
      "*"
    ]
  },
  "cache": true
}
        