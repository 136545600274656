import { Model } from 'pinia-orm';
import { Product } from '~/models';

export class ProductCategory extends Model {
  static override entity = 'orm/projects/products/categories';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      pointTypeId: this.attr(null),
      name: this.string(null),
      description: this.string(null),
      order: this.number(null),
      products: this.hasMany(Product, 'categoryId').onDelete('cascade'),
    };
  }

  get title() {
    return [this.name, this.description].filter(Boolean).join('\n');
  }

  _isOpenPopover: boolean = false;

  declare id: number;
  declare projectId: number;
  declare pointTypeId: number | null;
  declare name: string;
  declare description: string | null;
  declare order: number;
  declare products: Product[];
}
