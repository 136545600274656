export default defineAppConfig({
  ui: {
    primary: 'green',
    gray: 'cool',
    // icons: {
    //   dynamic: true,
    // },
    button: {
      default: {
        loadingIcon: 'i-bi-brightness-high',
      },
    },
    notifications: {
      position: 'top-12 bottom-auto',
    },
    container: {
      base: 'mx-auto min-h-full',
    },
    // table: {
    //   wrapper: 'min-h-full relative overflow-x-auto',
    //   base: 'min-w-full min-h-full table-fixed',
    // },
  },
  // icon: {
  //   localApiEndpoint: '/_api/_nuxt_icon',
  //   fallbackToApi: false,
  // },
});
