import mitt from 'mitt';
import type { LatLon } from '~/models';
import { Lead } from '~/models';
import type { FeatureCollection } from '~/types';

export type MapState = { center: LatLon, zoom: number };
export type TypeEditorTab = 'general' | 'categories' | 'catalog' | 'pointFields' | 'history';
export type PointEditorTab = 'general' |'prices' | 'delivery' | 'history';
export type LeadGeoResult = { coordinates: LatLon, address: string };
export type LeadRouteResult = { distance: number, duration: number, pointId: number, routeGeoJson?: FeatureCollection };

export type ApplicationEvents = {
  'view:set': 'list' | 'map';
  // type
  'type:create': void;
  'type:edit': { typeId: number, tab?: TypeEditorTab };
  'type:delete': number;
  // category
  'category:delete': number;
  // tag
  'tag:create': void;
  'tag:edit': number;
  'tag:delete': number;
  // point
  'point:create': void;
  'point:edit': { pointId: number, tab?: PointEditorTab };
  'point:trash': number;
  'point:deleted': number;
  'point:open': { pointId: number };
  'point:setActive': { pointId: number };
  // map
  'map:bounds:change': MapState;
  'map:click': { coordinates: LatLon },
  'map:object:balloon:show': { pointId: number };
  'map:object:balloon:close': void;
  'map:search:result:new': LeadGeoResult;
  'map:search:result:change': LeadGeoResult;
  'map:search:result:set': LeadGeoResult;
  'map:search:result:show': void
  'map:route:result': LeadRouteResult;
  'map:route:shown': void;
  'map:route:close': void;
  // leads
  'leads:history': void;
  // lead
  'lead:calculate': { lead: Lead, pointId: number };
  'lead:recalculate': Lead;
  'lead:show': { leadId: number };
  'lead:showCurrentInCenter': void;
};

const emitter = mitt<ApplicationEvents>();

export const useEmit = emitter.emit;
export const useListen = emitter.on;
export const useStopListen = emitter.off;
