import { Model } from 'pinia-orm';
import { PointTag } from '~/models';

export class PointTagCategory extends Model {
  static override entity = 'orm/projects/points/tags/categories';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      name: this.string(''),
      description: this.string(null),
      order: this.attr(null),
      tags: this.hasMany(PointTag, 'categoryId'),
    };
  }

  get title() {
    return [this.name, this.description].filter(Boolean).join('\n');
  }

  declare id: number;
  declare projectId: number;
  declare name: string;
  declare description: string;
  declare order: number;
  declare tags: PointTag[];
}
