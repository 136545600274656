import { Model } from 'pinia-orm';
import { Point, type PointIcon, PointType } from '~/models';

export class PointCategory extends Model {
  static override entity = 'orm/projects/points/categories';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      pointTypeId: this.attr(null),
      name: this.string(''),
      description: this.string(''),
      icon: this.attr({ type: 'circle', color: 'green' }),
      order: this.number(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      type: this.belongsTo(PointType, 'pointTypeId'),
      points: this.hasMany(Point, 'categoryId').onDelete('set null'),
    };
  }

  get title() {
    return [this.name, this.description].filter(Boolean).join('\n');
  }

  _deleting: boolean = false;

  declare id: number;
  declare projectId: number;
  declare pointTypeId: number;
  declare name: string;
  declare description: string;
  declare icon: PointIcon;
  declare order: number;
  declare createdAt: number | null;
  declare updatedAt: number | null;
  declare type: PointType;
  declare points: Point[];
}
