<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal';

useHead({
  link: [
    { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
    { rel: 'icon', href: '/favicon.ico', sizes: 'any' },
    { rel: 'icon', href: '/icon.svg', type: 'image/svg+xml' },
    { rel: 'manifest', href: '/manifest.webmanifest' },
  ],
});
</script>
<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UNotifications />
  <ModalsContainer />
</template>

<style lang="scss">
html,
body,
#__nuxt {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  height: 1px;
}
//#__nuxt {
//  background: url('/images/map-pattern.webp') repeat;
//}
#__nuxt > .layout {
  height: 100%;
  min-height: 100%;
}
</style>
