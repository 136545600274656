import { Model } from 'pinia-orm';
import type { ProjectUserSettings } from '~/types';
import { Role } from '~/models';

export class ProjectUser extends Model {
  static override entity = 'orm/pivot/projectUser';
  static override primaryKey = ['projectId', 'userId'];

  static override fields() {
    return {
      projectId: this.attr(null),
      userId: this.attr(null),
      roleId: this.attr(null),
      isOwner: this.boolean(false),
      needAuthorise: this.boolean(false),
      settings: this.attr({}),
      invitedBy: this.attr(null),
      invitedByName: this.attr(null),
      invitedByEmail: this.attr(null),
      invitedAt: this.attr(null),
      inviteAcceptedAt: this.attr(null),
      deactivatedAt: this.attr(null),
      role: this.belongsTo(Role, 'roleId'),
    };
  }

  declare projectId: number;
  declare userId: number;
  declare roleId: number | null;
  declare isOwner: boolean;
  declare needAuthorise: boolean;
  declare settings: ProjectUserSettings;
  declare invitedBy: number | null;
  declare invitedByName: string | null;
  declare invitedByEmail: string | null;
  declare invitedAt: number | null;
  declare inviteAcceptedAt: number | null;
  declare deactivatedAt: number | null;
  declare role: Role | null;
}
