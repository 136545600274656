import { Model } from 'pinia-orm';
import {
  Point,
  PointCategory,
  PointField,
  PointTag,
  PointTagCategory,
  PointType,
  Product,
  ProductCategory,
  ProductField,
  ProjectUser,
  ProjectUserSession,
  Role,
  User,
} from '~/models';

export enum MapProvider {
  YANDEX = 'yandex',
  OSM = 'osm',
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
  KZT = 'KZT',
}

export type ProjectSettings = {
  currency: Currency
  provider: MapProvider
  [MapProvider.YANDEX]: {
    apiKeys: string[]
    suggestKeys: string[]
  },
  [MapProvider.OSM]: {}
};

export class Project extends Model {
  static override entity = 'orm/projects';

  static override fields() {
    return {
      id: this.attr(null),
      name: this.string(''),
      slug: this.string(''),
      settings: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deletedAt: this.attr(null),
      // relations
      pointTypes: this.hasMany(PointType, 'projectId'),
      pointCategories: this.hasMany(PointCategory, 'projectId'),
      pointTagCategories: this.hasMany(PointTagCategory, 'projectId'),
      pointTags: this.hasMany(PointTag, 'projectId'),
      pointFields: this.hasMany(PointField, 'projectId'),
      points: this.hasMany(Point, 'projectId'),
      productCategories: this.hasMany(ProductCategory, 'projectId'),
      productFields: this.hasMany(ProductField, 'projectId'),
      products: this.hasMany(Product, 'projectId'),
      roles: this.hasMany(Role, 'projectId'),
      users: this.belongsToMany(User, ProjectUser, 'projectId', 'userId'),
      userSessions: this.hasMany(ProjectUserSession, 'projectId', 'id'),
    };
  }

  declare id: number;
  declare name: string;
  declare slug: string;
  declare settings: ProjectSettings;
  declare createdAt: number | null;
  declare updatedAt: number | null;
  declare deletedAt: number | null;
  declare pointTypes: PointType[];
  declare pointCategories: PointCategory[];
  declare pointTagCategories: PointTagCategory[];
  declare pointTags: PointTag[];
  declare pointFields: PointField[];
  declare points: Point[];
  declare productCategories: ProductCategory[];
  declare products: Product[];
  declare roles: Role[];
  declare users: User[];
  declare userSessions: ProjectUserSession[];
}
