import { defineStore } from 'pinia';
import type { NuxtApp } from '#app/nuxt';
import { setAuthUser } from '#imports';
import SiteAPI from '~/api/SiteAPI';
import { checkAuth } from '~/composables/auth';

export * from './project';
export * from './leads';

const rootStore = defineStore('root', {
  state() {
    return {
      initialized: false,
    };
  },
});

const init = async (nuxtApp: NuxtApp) => {
  try {
    const { authUser } = await SiteAPI.init();
    setAuthUser(authUser, nuxtApp);
    rootStore().initialized = true;
  } catch (e) {
    logger().error(e);
  }
};

export const serverInit = async (nuxtApp: NuxtApp) => {
  logger().debug('[init server]');
  await init(nuxtApp);
};

export const clientInit = async (nuxtApp: NuxtApp) => {
  logger().debug('[init client]');
  if (!rootStore().initialized || !checkAuth(nuxtApp)) {
    await init(nuxtApp);
  }
};
