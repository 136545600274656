import { Model } from 'pinia-orm';
import { Product } from '~/models';

export class ProductPrice extends Model {
  static override entity = 'orm/pivot/productPrice';
  static override primaryKey = ['productId', 'pointId'];

  static override fields() {
    return {
      productId: this.attr(null),
      pointId: this.attr(null),
      order: this.attr(null),
      priceCash: this.attr(null),
      priceCashless: this.attr(null),
      // relations
      product: this.belongsTo(Product, 'productId'),
    };
  }

  declare productId: number;
  declare pointId: number;
  declare order: number;
  declare priceCash: string;
  declare priceCashless: string;
  declare product: Product;
}
