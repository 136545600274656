import { Model } from 'pinia-orm';

export class PointFieldValue extends Model {
  static override entity = 'orm/pivot/pointFieldValue';

  static override fields() {
    return {
      id: this.attr(null),
      fieldId: this.attr(null),
      pointId: this.attr(null),
      enumId: this.attr(null),
      value: this.attr(null),
    };
  }

  declare id: number;
  declare fieldId: number;
  declare pointId: number;
  declare enumId: number | null;
  declare value: any;
}
