import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91userId_93vm7ITBo7uPMeta } from "/app/pages/admin/users/[userId].vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as _91projectSlug_937nnEUuf2TkMeta } from "/app/pages/auth/invited/[projectSlug].vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as recoveryZq4BhnV6caMeta } from "/app/pages/auth/password/recovery.vue?macro=true";
import { default as resetQrbREZtjSHMeta } from "/app/pages/auth/password/reset.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as emailxWl88Uy32UMeta } from "/app/pages/auth/verify/email.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as eventsJhva6tPt7jMeta } from "/app/pages/projects/[projectSlug]/events.vue?macro=true";
import { default as indexZHaw2BSsAQMeta } from "/app/pages/projects/[projectSlug]/index.vue?macro=true";
import { default as settingssWwbpXVKyEMeta } from "/app/pages/projects/[projectSlug]/settings.vue?macro=true";
import { default as usersC4p3yr34PzMeta } from "/app/pages/projects/[projectSlug]/users.vue?macro=true";
import { default as _91projectSlug_939jI623POzYMeta } from "/app/pages/projects/[projectSlug].vue?macro=true";
import { default as createfbNsHYdxaeMeta } from "/app/pages/projects/create.vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
import { default as projectsbEHPxy5SMHMeta } from "/app/pages/projects.vue?macro=true";
import { default as profile8S52gQnHEjMeta } from "/app/pages/settings/profile.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: admin0eiku7Jj1aMeta?.name,
    path: "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin",
    path: "",
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-userId",
    path: "users/:userId()",
    meta: _91userId_93vm7ITBo7uPMeta || {},
    component: () => import("/app/pages/admin/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/app/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-invited-projectSlug",
    path: "/auth/invited/:projectSlug()",
    component: () => import("/app/pages/auth/invited/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-recovery",
    path: "/auth/password/recovery",
    meta: recoveryZq4BhnV6caMeta || {},
    component: () => import("/app/pages/auth/password/recovery.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    meta: resetQrbREZtjSHMeta || {},
    component: () => import("/app/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify/email",
    meta: emailxWl88Uy32UMeta || {},
    component: () => import("/app/pages/auth/verify/email.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: projectsbEHPxy5SMHMeta?.name,
    path: "/projects",
    meta: projectsbEHPxy5SMHMeta || {},
    component: () => import("/app/pages/projects.vue").then(m => m.default || m),
    children: [
  {
    name: _91projectSlug_939jI623POzYMeta?.name,
    path: ":projectSlug()",
    component: () => import("/app/pages/projects/[projectSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectSlug-events",
    path: "events",
    component: () => import("/app/pages/projects/[projectSlug]/events.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectSlug",
    path: "",
    component: () => import("/app/pages/projects/[projectSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectSlug-settings",
    path: "settings",
    component: () => import("/app/pages/projects/[projectSlug]/settings.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectSlug-users",
    path: "users",
    component: () => import("/app/pages/projects/[projectSlug]/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "projects-create",
    path: "create",
    component: () => import("/app/pages/projects/create.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "",
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-profile",
    path: "profile",
    component: () => import("/app/pages/settings/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/auth",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/login",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/register",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/auth/password",
    component: component_45stubnO9MU04yTU
  }
]