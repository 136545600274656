import { Model } from 'pinia-orm';

export class PointDeliveryPrice extends Model {
  static override entity = 'orm/projects/points/deliveryPrices';

  static override fields() {
    return {
      id: this.attr(null),
      pointId: this.attr(null),
      distance: this.attr(null),
      price: this.attr(null),
    };
  }

  declare id: number;
  declare pointId: number;
  declare distance: number;
  declare price: string;
}
