import { Model } from 'pinia-orm';
import { PointFieldEnum, PointFieldValue } from '~/models';

export enum PointFieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  ENUM_SINGLE = 'enum_single',
  ENUM_MULTIPLE = 'enum_multiple',
}

export class PointField extends Model {
  static override entity = 'orm/projects/points/fields';

  static override fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(null),
      pointTypeId: this.attr(PointFieldType.TEXT),
      name: this.string(''),
      type: this.attr(null),
      order: this.number(null),
      enums: this.hasMany(PointFieldEnum, 'fieldId').onDelete('cascade'),
      values: this.hasMany(PointFieldValue, 'fieldId').onDelete('cascade'),
    };
  }

  declare id: number;
  declare projectId: number;
  declare pointTypeId: number;
  declare name: string;
  declare type: PointFieldType;
  declare order: number;
  declare enums: PointFieldEnum[];
  declare values: PointFieldValue[];

  declare pivot?: PointFieldValue;
}
