import { defineStore } from 'pinia';
import pick from 'lodash/pick';
import type { NuxtApp } from '#app/nuxt';
import { Ability, Permission, Project, ProjectUser, RolePermission, UserCurrent } from '~/models';
import type { AuthUser } from '~/types';
import AuthAPI from '~/api/AuthAPI';

const useAuthStore = defineStore('auth', () => {
  const userId = ref<number | null>(null);

  function setUserId(id: number | null) {
    userId.value = id;
  }

  return {
    userId,
    setUserId,
  };
});

export const checkAuth = (nuxtApp?: NuxtApp): boolean => {
  nuxtApp = nuxtApp || useNuxtApp();
  const authStore = useAuthStore();
  if (authStore.userId) {
    return !!useRepo(UserCurrent, nuxtApp.$pinia).find(authStore.userId);
  }
  return false;
};

export const setAuthUser = (user: AuthUser | null, nuxtApp?: NuxtApp) => {
  logger().debug('[setAuthUser]', user ? pick(user, ['id', 'name', 'email', 'emailVerifiedAt']) : null);
  nuxtApp = nuxtApp || useNuxtApp();
  const authStore = useAuthStore();
  if (user) {
    useRepo(UserCurrent, nuxtApp.$pinia).save(user);
    authStore.setUserId(user.id);
  } else if (authStore.userId) {
    authStore.setUserId(null);
    useRepo(UserCurrent, nuxtApp.$pinia).destroy(authStore.userId);
  }
};

export const updateAuthUser = (attr: object) => {
  logger().debug('[updateAuthUser]', attr);
  const authStore = useAuthStore();
  if (!authStore.userId) {
    throw new Error('Cannot update authUser');
  }
  const userRepo = useRepo(UserCurrent);
  userRepo.save(Object.assign({}, attr, { id: authStore.userId }));
};

export const logout = async () => {
  await AuthAPI.logout();
  setAuthUser(null);
  navigateTo('/');
};

export const getAuthUser = (withRoles: boolean = false): UserCurrent | null => {
  const authStore = useAuthStore();
  if (authStore.userId) {
    const userRepo = useRepo(UserCurrent);
    return withRoles
      ? userRepo.with('roles', q => q.with('permissions')).find(authStore.userId)
      : userRepo.find(authStore.userId);
  }
  return null;
};

export const getAuthUserId = () => {
  return useAuthStore().userId;
};

export const isAuthenticated = (): boolean => {
  return getAuthUser() !== null;
};

export const can = (
  ability: Ability,
  projectIdOrSlug?: string | number | null | undefined,
  typeId?: number,
  resourceId?: number,
): boolean => {
  const authUser = getAuthUser(true);
  if (!authUser) {
    return false;
  }

  for (const role of authUser.roles.filter(({ projectId }) => projectId === null)) {
    if (role.name === 'root') {
      return true;
    }
    for (const permission of role.permissions) {
      if (permission.name === ability) {
        return true;
      }
    }
  }

  if (projectIdOrSlug) {
    const project = typeof projectIdOrSlug === 'number'
      ? useRepo(Project).find(projectIdOrSlug)
      : useRepo(Project).where('slug', projectIdOrSlug).first();
    if (!project) {
      return false;
    }
    const projectUser = useRepo(ProjectUser).find(`[${project.id},${authUser.id}]`);
    if (projectUser?.isOwner) {
      return true;
    }

    const rolePermissionRepo = useRepo(RolePermission);
    for (const role of authUser.roles.filter(({ projectId }) => projectId === project.id)) {
      for (const permission of role.permissions) {
        if (permission.name === ability) {
          permission.pivot = rolePermissionRepo.find(`[${role.id},${permission.id}]`);
          const res = checkPermission(permission, typeId, resourceId);
          return res;
        }
      }
    }
  }

  return false;
};

const checkPermission = (permission: Permission, typeId?: number, resourceId?: number): boolean => {
  const { only, types } = permission.pivot?.settings ?? {} as { types?: number[], only?: number[] };
  switch (permission.name) {
    case Ability.TYPES_VIEW:
    case Ability.TYPES_EDIT:
      return !typeId || !only || only.includes(typeId);
    case Ability.FIELDS_CREATE:
    case Ability.FIELDS_DELETE:
    case Ability.PRODUCTS_CREATE:
    case Ability.PRODUCTS_EDIT:
    case Ability.PRODUCTS_DELETE:
      return !typeId || !types || types.includes(typeId);
    case Ability.FIELDS_VIEW:
    case Ability.FIELDS_EDIT:
      return (!typeId || !types || types.includes(typeId)) &&
        (!resourceId || !only || only.includes(resourceId));
    default:
      return true;
  }
};
