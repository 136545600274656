import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import type { NuxtApp } from '#app/nuxt';

const importLocale = async (locale: string) => {
  switch (locale) {
    case 'ru':
      return await import('dayjs/locale/ru');
    default:
      return await import('dayjs/locale/en');
  }
};

const setLocale = async (locale: string) => {
  await importLocale(locale);
  dayjs.locale(locale);
};

export default defineNuxtPlugin((nuxtApp) => {
  dayjs.extend(LocalizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(duration);

  nuxtApp.hook('app:created', async () => {
    await setLocale((nuxtApp as NuxtApp).$i18n.locale.value);
  });
  nuxtApp.hook('i18n:beforeLocaleSwitch', async ({ oldLocale, newLocale, initialSetup }) => {
    await setLocale(newLocale);
  });
});
