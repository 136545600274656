import { Model } from 'pinia-orm';

export class RoleUser extends Model {
  static override entity = 'orm/auth/roleUser';
  static override primaryKey = ['roleId', 'userId'];
  static override fields() {
    return {
      roleId: this.number(null),
      userId: this.number(null),
      projectId: this.attr(null),
    };
  }

  declare roleId: number;
  declare userId: number;
  declare projectId: number | null;
}
